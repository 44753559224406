<template>
  <div class="menu">
    <div style="margin-bottom: 20px">
      <el-button type="success" @click="handleChangeAdd">新增</el-button>
      <el-button type="warning" @click="toggleExpandAll">展开/折叠</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      :default-expand-all="false"
      @expand-change="changeExpand"
      ref="tableTreeRef"
    >
      <el-table-column prop="name" label="菜单名称" />
      <el-table-column prop="type" label="类型">
        <template #default="scoped">
          <span v-if="scoped.row.type == 1">目录</span>
          <span v-if="scoped.row.type == 2">菜单</span>
        </template>
      </el-table-column>
      <el-table-column prop="menuStatus" label="状态">
        <template #default="scoped">
          <span v-if="scoped.row.menuStatus === 0">正常</span>
          <span v-if="scoped.row.menuStatus === 1">停用</span>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" />
      <el-table-column prop="createTime" label="更新时间" />
      <el-table-column label="操作">
        <template #default="scoped">
          <el-button
            type="primary"
            size="small"
            @click="handleChangeAdd(scoped.row)"
          >
            新增
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="handleChangeEdit(scoped.row)"
          >
            编辑
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="handleChangeDelete(scoped.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="menuvisible"
      :title="menuTitle"
      width="500"
      :before-close="menuClose"
    >
      <el-form
        ref="menuFormRef"
        :model="menuForm"
        :rules="menuFormRules"
        label-width="120px"
        class="menuform"
      >
        <el-form-item label="菜单类型：" prop="type">
          <el-radio-group v-model="menuForm.type">
            <el-radio
              v-for="(item, i) in typeList"
              :key="i"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="父级菜单："
          prop="father"
          v-if="menuForm.type == 2"
        >
          <!-- <el-select v-model="menuForm.father" placeholder="请选择">
            <el-option
              v-for="item in fatherList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <el-tree-select
            v-model="menuForm.father"
            :data="fatherList"
            check-strictly
            default-expand-all
            node-key="id"
            :props="props"
            :render-after-expand="false"
          />
        </el-form-item>
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="menuForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="路由路径：" prop="url">
          <el-input v-model="menuForm.url" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="是否显示：" prop="show">
          <el-radio-group v-model="menuForm.show">
            <el-radio
              v-for="(item, i) in showList"
              :key="i"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
          <p>选择隐藏则路由将不会出现在侧边栏，但仍然可以访问</p>
        </el-form-item>
        <!-- <el-form-item label="菜单状态：" prop="state">
          <el-radio-group v-model="menuForm.state">
            <el-radio
              v-for="(item, i) in stateList"
              :key="i"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
          <p>选择停用则路由将不会出现在侧边栏，也不能被访问</p>
        </el-form-item> -->
        <el-form-item label="排序：" prop="sort">
          <el-input v-model="menuForm.sort" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="menuSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import store from '@/store'
import {
  menuPage,
  menuDelete,
  menuUpdate,
  menuCreate
} from '@/api/permissionManagement/menu'
const tableTreeRef = ref()
const menuFormRef = ref()
const initData = reactive({
  loading: false,
  tableData: [],
  defaultExpandAll: false,
  menuvisible: false,
  menuTitle: '',
  menuForm: {
    id: '',
    type: 1,
    father: '',
    url: '',
    show: true,
    state: 0
  },
  menuFormRules: {
    type: [
      {
        required: true,
        message: '请选择菜单类型',
        trigger: 'change'
      }
    ],
    father: [
      {
        required: true,
        message: '请选择父级菜单',
        trigger: 'change'
      }
    ],
    name: [
      {
        required: true,
        message: '请输入菜单名称',
        trigger: 'blur'
      }
    ],
    url: [
      {
        required: true,
        message: '请输入路由路径',
        trigger: 'blur'
      }
    ],
    show: [
      {
        required: true,
        message: '请选择是否显示',
        trigger: 'change'
      }
    ],
    state: [
      {
        required: true,
        message: '请选择菜单状态',
        trigger: 'change'
      }
    ],
    sort: [
      {
        required: true,
        message: '请输入排序',
        trigger: 'blur'
      }
    ]
  },
  typeList: [
    {
      label: '目录',
      value: 1
    },
    {
      label: '菜单',
      value: 2
    }
  ],
  showList: [
    {
      label: '显示',
      value: true
    },
    {
      label: '隐藏',
      value: false
    }
  ],
  stateList: [
    {
      label: '正常',
      value: 0
    },
    {
      label: '停用',
      value: 1
    }
  ],
  fatherList: []
})

const {
  loading,
  tableData,
  defaultExpandAll,
  menuvisible,
  menuTitle,
  menuForm,
  menuFormRules,
  typeList,
  showList,
  stateList,
  fatherList
} = toRefs(initData)
const props = {
  label: 'name',
  children: 'children'
}
onMounted(() => {
  getDataList()
})

// 新增
const handleChangeAdd = row => {
  menuTitle.value = '新增菜单'
  if (row.id) {
    menuForm.value.type = 2
    menuForm.value.id = ''
    menuForm.value.father = row.id
    getDataList()
  } else {
    menuForm.value.type = 1
  }
  menuvisible.value = true
}

// 编辑
const handleChangeEdit = row => {
  menuTitle.value = '编辑菜单'
  menuForm.value.name = row.name
  menuForm.value.type = row.type
  menuForm.value.sort = row.sort
  menuForm.value.url = row.path
  menuForm.value.show = row.visible
  menuForm.value.state = 0
  menuForm.value.father = row.parentId
  menuForm.value.id = row.id
  getDataList()
  menuvisible.value = true
}

const handleChangeDelete = row => {
  ElMessageBox.confirm('删除后该菜单无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      menuDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
          store.dispatch('app/menuLists')
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 关闭
const menuClose = () => {
  menuForm.value = {
    id: '',
    type: 1,
    father: '',
    url: '',
    show: true,
    state: 0
  }
  menuFormRef.value.resetFields()
  menuvisible.value = false
}

// 提交
const menuSubmit = () => {
  if (!menuFormRef.value) return
  menuFormRef.value.validate(valid => {
    if (valid) {
      const data = {
        name: menuForm.value.name,
        type: menuForm.value.type,
        sort: menuForm.value.sort,
        path: menuForm.value.url,
        parentId: 0,
        visible: menuForm.value.show,
        menuStatus: 0
      }
      if (menuForm.value.type == 2) {
        data.parentId = menuForm.value.father
      }
      if (menuForm.value.id == '') {
        menuCreate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            menuClose()
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = menuForm.value.id
        menuUpdate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            menuClose()
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!')
      return false
    }
  })
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  menuPage().then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      tableData.value = res.data
      fatherList.value = res.data
    } else {
      ElMessage.error(res.msg)
      tableData.value = []
    }
  })
}

// 展开/折叠
const changeExpand = (row, expandedRows) => {
  defaultExpandAll.value = expandedRows
}
const toggleExpandAll = () => {
  if (tableData.value.length == 0) {
    return false
  }
  defaultExpandAll.value = !defaultExpandAll.value
  toggleRowExpansionAll(tableData.value, defaultExpandAll.value)
}
const toggleRowExpansionAll = (data, defaultExpandAll) => {
  data.forEach(item => {
    tableTreeRef.value.toggleRowExpansion(item, defaultExpandAll)
    if (item.children !== undefined && item.children !== null) {
      toggleRowExpansionAll(item.children, !defaultExpandAll)
    }
  })
}
</script>
<style lang="scss" scoped></style>
